@import '../../../scss/index.scss';

.basket-form {
    &__container {
        padding: 0 10px;
        .ant-typography {
            margin-bottom: 0;
        }
        .ant-collapse-arrow {
            left: 0px !important;
        }
        .ant-collapse-header {
            padding-left: 0px;
        }

        &-groupsItem {
            height: 330px;
            overflow-y: scroll;
        }
        &-add {
            margin: 10px 0;
            text-align: right;
        }
        &-image {
            .ant-image {
                width: 60px;
                position: absolute;
                left: 10px;
                top: -11px;
            }
        }
        &-right {
            text-align: right;
            .ant-typography {
                font-weight: 500;
            }
        }

        &-items {
            background-color: $background-grey-white;
            padding: 10px;
            margin-bottom: 10px;

            h4 {
                margin-bottom: 0px;
                text-transform: capitalize;
            }
            .ant-col.ant-col-14 {
                .gray {
                    color: #808080 !important;
                    margin-bottom: 0px;
                }
            }
            .ant-row.counter {
                text-align: center;
                .right {
                    text-align: right;
                }
            }

            .section-items {
                text-transform: capitalize;
            }
        }

        &-paypal {
            text-align: center;
            margin-bottom: 20px;
            .change {
                margin-top: 20px;
                &-left {
                    text-align: center;
                }
            }
        }
        &-cost-delivery {
            text-align: center;
            margin-bottom: 15px;
        }
    }
    .empty-basket {
        margin-bottom: 20px;
    }

    .ant-skeleton.ant-skeleton-active.cost-delivey {
        h3 {
            margin: 0;
        }
    }
}
.cost-delivery__container {
    padding: 20px;
}

.cost-delivery__container-address {
    .ant-divider {
        margin-top: 0;
    }
    button {
        margin-top: 20px;
    }
}
.mt-0 {
    margin-top: 0 !important;
}
.margin-left {
    margin-left: 5px;
}

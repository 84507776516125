@import '../../../scss/index.scss';

.schedule-form{
    margin-top: 20;
    .ant-form label {
        font-size: 16px;
    }

    &__container{
        width: 100%;
        margin-top: 20px;
    }

    &__container-hours{
        width: 100%;
        margin-top: -10px;
        .ant-col.ant-col-12{
            padding: 0 !important;
        }
    }
    &__time{
        .ant-col.ant-form-item-label{
            padding-bottom: 0;
        }

    }

    
}

// a.ant-picker-now-btn{
//     visibility: hidden; 
// }
// a.ant-picker-now-btn::after{
//     content: 'Actual';
//     visibility: visible;
// }
@import '../../../scss/index.scss';

.counter{
    font-size: 22px;
    font-weight: bold;

    &__number{
        text-align: center;
    }
}

.card-order{
    margin-bottom: 20px;
    .ant-card-body{
        padding: 8px;
    }
}

.mt-20{
    margin-top: 20px;
}
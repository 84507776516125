@import '../../scss/index.scss';

.client {
    height: calc(100vh - 85px);
    background-color: $background-light;

    .ant-spin-spinning{

        top: 40%;
        position: absolute;
        left: 0;
        right: 0;
    }

    &__container-update{
        text-align: center;
    }
}

@import '../../../scss/index.scss';

.register-form{
    padding: 20px;
    // margin-top: 68px;
    
    .ant-row.ant-form-item{
        margin-bottom: 5px;
    }

    &__container{
        text-align: center;
    }
}